import React, {useEffect, useState, useRef} from "react"
import Layout from "../components/layout"
// import Lottie from "react-lottie"
import Lottie, { LottiePlayer } from "lottie-react"
// import { Link } from "gatsby"
// import lottie from "lottie-web"

// components
import JoinServbees from '../components/section-join-servbees'
import SEO from "../components/seo"


// JSON Animations
// SELL
import sellAnimation1 from '../assets/animation/HowTo-Sell-1.json'
import sellAnimation2 from '../assets/animation/HowTo-Sell-2.json'
import sellAnimation3 from '../assets/animation/HowTo-Sell-3.json'

// OFFER
import offerAnimation1 from '../assets/animation/HowTo-Offer-1.json'
import offerAnimation2 from '../assets/animation/HowTo-Offer-2.json'
import offerAnimation3 from '../assets/animation/HowTo-Offer-3.json'

// NEED
import needAnimation1 from '../assets/animation/HowTo-Need-1.json'
import needAnimation2 from '../assets/animation/HowTo-Need-2.json'
import needAnimation3 from '../assets/animation/HowTo-Need-3.json'


// SVG's/Icons
import field from '../assets/images/how-to/field.svg'
import mField from '../assets/images/how-to/m-field.svg'
import sellIcon from '../assets/images/how-to/icon-sell.svg'
import serviceIcon from '../assets/images/how-to/icon-service.svg'
import needIcon from '../assets/images/how-to/icon-need.svg'

import sellIconM from '../assets/images/how-to/ico-sell-m.png'
import serviceIconM from '../assets/images/how-to/ico-services-m.png'
import needIconM from '../assets/images/how-to/ico-needs-m.png'

// Hexes
import sellHex from '../assets/images/how-to/sell-hex.png'
import serviceHex from '../assets/images/how-to/service-hex.png'
import needHex from '../assets/images/how-to/need-hex.png'

import mSellHex from '../assets/images/how-to/m-sell-hex.svg'
import mServiceHex from '../assets/images/how-to/m-service-hex.svg'
import mNeedHex from '../assets/images/how-to/m-need-hex.svg'

// BG's
import sellBG from "../assets/images/how-to/sell-bg.png"
import serviceBG from "../assets/images/how-to/service-bg.png"
import needBG from "../assets/images/how-to/need-bg.png"


const HowTo = () => {

    const [isMobile, setIsMobile] = useState(false)
    const [autoplay, setAutoplay] = useState(false)
    const [sellAutoplay, setSellAutoplay] = useState(false)
    const [serviceAutoplay, setServiceAutoplay] = useState(false)
    const [needAutoplay, setNeedAutoplay] = useState(false)

    const [currentJSONAnim, setCurrentJSONAnim] = useState(sellAnimation1)
    const [currentSellAnim, setCurrentSellAnim] = useState(sellAnimation1)
    const [currentServiceAnim, setCurrentServiceAnim] = useState(offerAnimation1)
    const [currentNeedAnim, setCurrentNeedAnim] = useState(needAnimation1)

    const [stepIcon, setStepIcon] = useState(sellIcon)
    const [stepTitle, setStepTitle] = useState('Sell without hassle')

    const [text1, setText1] = useState('Post photos of what you’re selling.')
    const [text2, setText2] = useState('Add all the details about the product and your biz.')
    const [text3, setText3] = useState('Hit publish and sell!')

    const lottieRef = useRef();

    const stickyCallback = sticky => {
        if (sticky.isIntersecting === true && sticky.intersectionRatio >= 0.5) {
            console.log("sticky intersection 0.5")
        }
    }

    const callbackFunction = entries => {
        const bgDiv = document.getElementById("bg-div")
        const nums = document.getElementsByClassName('text-32')

        const nav = document.querySelector('.header')

        const stepTitleContainer = document.querySelectorAll(".step-title-container")

        const sellStep1 = document.getElementById("sellText1")
        const sellStep2 = document.getElementById("sellText2")
        const sellStep3 = document.getElementById("sellText3")

        const serviceStep1 = document.getElementById("serviceText1")
        const serviceStep2 = document.getElementById("serviceText2")
        const serviceStep3 = document.getElementById("serviceText3")

        const needStep1 = document.getElementById("needText1")
        const needStep2 = document.getElementById("needText2")
        const needStep3 = document.getElementById("needText3")

        entries.forEach((entry, a,b) => {

            if (entry.isIntersecting === true && entry.target.className.includes("sell-section") && entry.intersectionRatio >= 0.5 && entry.intersectionRatio <= 1) {

                nav.classList.add('header-scroll')

                if (stepTitleContainer !== null && stepTitleContainer.length > 2) {
                    stepTitleContainer[0].classList.remove("fade-in-left")
                    sellStep1.classList.remove("fade-in-left")
                    stepTitleContainer[1].classList.remove("fade-in-left")
                    sellStep2.classList.remove("fade-in-left")
                    stepTitleContainer[2].classList.remove("fade-in-left")
                    sellStep3.classList.remove("fade-in-left")
                }

                if (sellStep1 !== null) {
                    sellStep1.classList.remove("fade-in-left")
                }

                if (typeof window !== "undefined" && window.innerWidth < 1024) {


                    setCurrentSellAnim(sellAnimation1)
                    setSellAutoplay(true)

                    setCurrentServiceAnim(offerAnimation1)
                    setServiceAutoplay(false)

                    setCurrentNeedAnim(needAnimation1)
                    setNeedAutoplay(false)

                    serviceStep1.style.opacity = 0;
                    serviceStep2.style.opacity = 0;
                    serviceStep3.style.opacity = 0;
                    needStep1.style.opacity = 0;
                    needStep2.style.opacity = 0;
                    needStep3.style.opacity = 0;

                    stepTitleContainer[0].classList.add("fade-in-left")
                    sellStep1.classList.add("fade-in-left")

                    stepTitleContainer[0].style.opacity = 1;
                    sellStep1.style.opacity = 1;
                } else {
                    bgDiv.style.background = `url(${sellBG}) rgb(48, 87, 186) no-repeat 100% 100%`

                    nums[0].style.color = '#3057BA'
                    nums[1].style.color = '#3057BA'
                    nums[2].style.color = '#3057BA'

                    document.getElementById("text1").style.opacity = 0;
                    document.getElementById("text2").style.opacity = 0;
                    document.getElementById("text3").style.opacity = 0;


                    setCurrentJSONAnim(sellAnimation1)
                    setAutoplay(true)
                    setStepIcon(sellIcon)

                    setStepTitle('Sell without hassle')
                    setText1('Post photos of what you’re selling.')
                    setText2('Add all the details about the product and your biz.')
                    setText3('Hit publish and sell!')

                    document.getElementById("text1").style.opacity = 1;

                    function displayTexts() {

                        if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '0' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text2").style.opacity = 1;
                            document.getElementById("text2").classList.add("fade-in-left");

                        } else if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '1' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text3").style.opacity = 1;
                            document.getElementById("text3").classList.add("fade-in-left");


                        }else if (document.getElementById("text3") && document.getElementById("text3").style.opacity === '1' && document.getElementById("text2").style.opacity === '1') {
                            clearInterval(interval)
                        } else {
                            clearInterval(interval)
                        }

                    }

                    let interval = setInterval(displayTexts, 1000);
                }

            }

            if (entry.isIntersecting === true && entry.target.className.includes("service-section") && entry.intersectionRatio >= 0.5 && entry.intersectionRatio <= 1) {

                nav.classList.add('header-scroll')

                if (stepTitleContainer !== null && stepTitleContainer.length > 2) {
                    stepTitleContainer[0].classList.remove("fade-in-left")
                    stepTitleContainer[1].classList.remove("fade-in-left")
                    stepTitleContainer[2].classList.remove("fade-in-left")
                }

                if (serviceStep1 !== null) {
                    serviceStep1.classList.remove("fade-in-left")
                }

                if (typeof window !== "undefined" && window.innerWidth < 1024) {
                    setCurrentSellAnim(sellAnimation1)
                    setSellAutoplay(false)

                    setCurrentServiceAnim(offerAnimation1)
                    setServiceAutoplay(true)

                    setCurrentNeedAnim(needAnimation1)
                    setNeedAutoplay(false)

                    sellStep1.style.opacity = 0;
                    sellStep2.style.opacity = 0;
                    sellStep3.style.opacity = 0;
                    needStep1.style.opacity = 0;
                    needStep2.style.opacity = 0;
                    needStep3.style.opacity = 0;

                    stepTitleContainer[1].classList.add("fade-in-left")
                    serviceStep1.classList.add("fade-in-left")

                    stepTitleContainer[1].style.opacity = 1;
                    serviceStep1.style.opacity = 1;


                } else {
                    bgDiv.style.background = `url(${serviceBG}) rgb(231, 133, 138) no-repeat 100% 100%`

                    nums[0].style.color = '#EA646C'
                    nums[1].style.color = '#EA646C'
                    nums[2].style.color = '#EA646C'

                    document.getElementById("text1").style.opacity = 0;
                    document.getElementById("text2").style.opacity = 0;
                    document.getElementById("text3").style.opacity = 0;

                    setCurrentJSONAnim(offerAnimation1)
                    setStepIcon(serviceIcon)
                    setAutoplay(true)

                    setStepTitle('Earn from your skills')
                    setText1('Post any services you like to offer: side gigs, creative skills, even unique services.')
                    setText2('Include your working arrangements, schedule, price range, and other important details.')
                    setText3('Accept bookings and earn from your side hustles!')

                    document.getElementById("text1").style.opacity = 1;

                    function displayTexts() {

                        if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '0' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text2").style.opacity = 1;
                            document.getElementById("text2").classList.add("fade-in-left");

                        } else if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '1' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text3").style.opacity = 1;
                            document.getElementById("text3").classList.add("fade-in-left");


                        }else if (document.getElementById("text3") && document.getElementById("text3").style.opacity === '1' && document.getElementById("text2").style.opacity === '1') {
                            clearInterval(interval)
                        } else {
                            clearInterval(interval)
                        }

                    }

                    let interval = setInterval(displayTexts, 1000);
                }

            }

            if (entry.isIntersecting === true && entry.target.className.includes("need-section") && entry.intersectionRatio >= 0.5 && entry.intersectionRatio <= 1) {

                nav.classList.add('header-scroll')

                if (stepTitleContainer !== null && stepTitleContainer.length > 2) {
                    stepTitleContainer[0].classList.remove("fade-in-left")
                    stepTitleContainer[1].classList.remove("fade-in-left")
                    stepTitleContainer[2].classList.remove("fade-in-left")
                }

                if (needStep1 !== null) {
                    needStep1.classList.remove("fade-in-left")
                }

                if (typeof window !== "undefined" && window.innerWidth < 1024) {
                    setCurrentSellAnim(sellAnimation1)
                    setSellAutoplay(false)

                    setCurrentServiceAnim(offerAnimation1)
                    setServiceAutoplay(false)

                    setCurrentNeedAnim(needAnimation1)
                    setNeedAutoplay(true)

                    sellStep1.style.opacity = 0;
                    sellStep2.style.opacity = 0;
                    sellStep3.style.opacity = 0;
                    serviceStep1.style.opacity = 0;
                    serviceStep2.style.opacity = 0;
                    serviceStep3.style.opacity = 0;

                    stepTitleContainer[2].classList.add("fade-in-left")
                    needStep1.classList.add("fade-in-left")

                    stepTitleContainer[2].style.opacity = 1;
                    needStep1.style.opacity = 1;
                } else {
                    bgDiv.style.background = `url(${needBG}) rgb(63, 191, 161) no-repeat 100% 0%`

                    nums[0].style.color = '#00BB94'
                    nums[1].style.color = '#00BB94'
                    nums[2].style.color = '#00BB94'

                    document.getElementById("text1").style.opacity = 0;
                    document.getElementById("text2").style.opacity = 0;
                    document.getElementById("text3").style.opacity = 0;

                    setCurrentJSONAnim(needAnimation1)
                    setAutoplay(true)
                    setStepIcon(needIcon)

                    setStepTitle('Find what you need nearby')
                    setText1('Post what you’re looking for')
                    setText2('Add the details of the item or service, including your budget.')
                    setText3('Wait for offers, review each option, then accept the best deal.')

                    document.getElementById("text1").style.opacity = 1;

                    function displayTexts() {

                        if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '0' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text2").style.opacity = 1;
                            document.getElementById("text2").classList.add("fade-in-left");

                        } else if (document.getElementById("text2") && document.getElementById("text2").style.opacity === '1' && document.getElementById("text3").style.opacity === '0') {
                            document.getElementById("text3").style.opacity = 1;
                            document.getElementById("text3").classList.add("fade-in-left");


                        }else if (document.getElementById("text3") && document.getElementById("text3").style.opacity === '1' && document.getElementById("text2").style.opacity === '1') {
                            clearInterval(interval)
                        } else {
                            clearInterval(interval)
                        }

                    }

                    let interval = setInterval(displayTexts, 1000);
                }

            }
        })
    }

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)

        const sections = document.querySelectorAll('.section-transition')

        const stickyContainer = document.querySelector('.sticky-container')

        if (typeof window !== "undefined" && window.innerWidth < 1024) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

        typeof window !== "undefined" && window.addEventListener('resize', function(e) {
            if (e.target.innerWidth < 1024) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        })

        const stickyObserver = new IntersectionObserver(stickyCallback, {
            root: null,
            rootMargin: '0px',
            threshold: 0.2
        })

        stickyObserver.observe(stickyContainer)

        sections.forEach(section => {
            observer.observe(section)

            return () => {
                if (section) observer.unobserve(section)
            }
        })

    }, [])

    return (
        <Layout className="create-post" >
            <SEO
                pageTitle="How To"
                title="How To | Servbees"
                description="Want tips on how to use Servbees? This is your quick guide to buy, sell, look for, and more on the app."
            />
            <div className="options-section">

                <div className="header-container">
                    <h2>How to Create a Post</h2>
                    <p>Sell products. Offer services. Find deals.</p>
                </div>

                <div className="hex-container">

                    <a href="#Sell" className="hex">
                        <img src={isMobile ? mSellHex : sellHex} alt="" />
                        <p className="text-24">I want to <br className="show-on-tablet" /><strong>Sell</strong></p>
                    </a>

                    <a href="#Service" className="hex">
                        <img src={isMobile ? mServiceHex : serviceHex} alt="" />
                        <p className="text-24">I want to <br className="show-on-tablet" /><strong>Offer Services</strong></p>
                    </a>

                    <a href="#Need" className="hex">
                        <img src={isMobile ? mNeedHex : needHex} alt="" />
                        <p className="text-24">I want to <br className="show-on-tablet" /><strong>Find Deals</strong></p>
                    </a>

                </div>

                <div className="scroll-icon-container">
                    <a href="#Sell">
                        <div className="scroll-down"></div>
                    </a>
                </div>

                <img src={mField} className="m-field-svg" alt="" />
            </div>


            <div className="relative-container">

                <div className="transitions-container" style={{ position: "absolute", top: 0 }}>
                    <div id="Sell" className="sell-section section-transition"></div>
                    <div id="Service" className="service-section section-transition"></div>
                    <div id="Need" className="need-section section-transition"></div>
                </div>

                {!isMobile ? (
                    <div className="sticky-container" style={{ position: "sticky", top: 0, zIndex: 5 }}>
                    <div id="bg-div"></div>

                    <img src={field} className="field-svg" alt="" />

                        <div className="inner-sticky-container">
                            <div className="left-sticky-container">
                                <div id="phoneJSON">

                                    <Lottie
                                        id="json-anim"
                                        animationData={currentJSONAnim}
                                        loop={false}
                                        autoplay={autoplay}
                                        lottieRef={lottieRef}
                                        onComplete={() => {
                                            // Sell Sequence
                                            if (currentJSONAnim.layers[1].nm.includes("Sell 01")) {
                                                setCurrentJSONAnim(sellAnimation2)
                                            }
                                            if (currentJSONAnim.nm.includes("Sell-02")) {
                                                setCurrentJSONAnim(sellAnimation3)
                                            }
                                            if (currentJSONAnim.nm.includes("Sell-03")) {
                                                setCurrentJSONAnim(sellAnimation1)
                                            }

                                            // Offer Sequence
                                            if (currentJSONAnim.layers[2].nm.includes("Offer 01")) {
                                                setCurrentJSONAnim(offerAnimation2)
                                            }
                                            if (currentJSONAnim.layers[2].nm.includes("Offer 02")) {
                                                setCurrentJSONAnim(offerAnimation3)
                                            }
                                            if (currentJSONAnim.layers[2].nm.includes("Offer 03")) {
                                                setCurrentJSONAnim(offerAnimation1)
                                            }

                                            // Need Sequence
                                            if (currentJSONAnim.layers[2].nm.includes("Need 01")) {
                                                setCurrentJSONAnim(needAnimation2)
                                            }
                                            if (currentJSONAnim.layers.length > 4 && currentJSONAnim.layers[4].nm.includes("Need 02")) {
                                                setCurrentJSONAnim(needAnimation3)
                                            }
                                            if (currentJSONAnim.layers[2].nm.includes("Need 03")) {
                                                setCurrentJSONAnim(needAnimation1)
                                            }
                                        }}
                                    />
                                </div>
                                {/* end phone JSON */}
                            </div>

                            <div className="right-sticky-container">
                                <div className="container-45vh">
                                    <div className="step-title-container">
                                        <img id="step-icon" src={stepIcon} alt="" />
                                        <h3 className="text-42">{stepTitle}</h3>
                                    </div>
                                </div>
                                <div className="step-title-and-text-container">
                                    <div className="steps-container">
                                        <div id="text1" className="step"><h3 className="text-32">1</h3><p className="steps-text text-24">{text1}</p></div>
                                        <div id="text2" className="step"><h3 className="text-32">2</h3><p className="steps-text text-24">{text2}</p></div>
                                        <div id="text3" className="step"><h3 className="text-32">3</h3><p className="steps-text text-24">{text3}</p></div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                ) : (
                    <>
                        {/* // Sell Section */}
                        <div className="mobile-container sell">

                            <div className="mobile-bg sell"></div>

                            <div className="inner-mobile-container">
                                <div className="top">
                                    <div className="step-title-container">
                                        <img id="step-icon" src={sellIconM} alt="" />
                                        <h3 className="text-42">Sell without hassle</h3>
                                    </div>
                                    <div className="steps-container">
                                        <div id="sellText1" className="step"><h3 className="text-32">1</h3><p className="steps-text text-24">Post photos of what you’re selling.</p></div>
                                        <div id="sellText2" className="step"><h3 className="text-32">2</h3><p className="steps-text text-24">Add all the details about the product and your biz.</p></div>
                                        <div id="sellText3" className="step"><h3 className="text-32">3</h3><p className="steps-text text-24">Hit publish and sell!</p></div>
                                    </div>
                            </div>
                                <div className="bottom">
                                    <div id="phoneJSON">
                                        <Lottie
                                            id="json-anim"
                                            animationData={currentSellAnim}
                                            loop={false}
                                            autoplay={sellAutoplay}
                                            lottieRef={lottieRef}
                                            onComplete={() => {
                                                if (currentSellAnim.layers[1].nm.includes("Sell 01")) {

                                                    setCurrentSellAnim(sellAnimation2)
                                                    document.getElementById("sellText2").style.opacity = 1;
                                                    document.getElementById("sellText2").classList.add("fade-in-left");
                                                }

                                                if (currentSellAnim.nm.includes("Sell-02")) {

                                                    setCurrentSellAnim(sellAnimation3)
                                                    document.getElementById("sellText3").style.opacity = 1;
                                                    document.getElementById("sellText3").classList.add("fade-in-left");
                                                }

                                                if (currentSellAnim.nm.includes("Sell-03")) {
                                                    setCurrentSellAnim(sellAnimation1)
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* <div className="phone-shadow"></div> */}
                                </div>
                            </div>
                        </div>
                        {/* // End Sell Section */}

                        {/* // Service Section */}
                        <div className="mobile-container service">

                            <div className="mobile-bg service"></div>

                            <div className="inner-mobile-container">
                                <div className="top">
                                    <div className="step-title-container">
                                        <img id="step-icon" src={serviceIconM} alt="" />
                                        <h3 className="text-42">Earn from your skills</h3>
                                    </div>
                                    <div className="steps-container">
                                        <div id="serviceText1" className="step"><h3 className="text-32">1</h3><p className="steps-text text-24">Post any services you like to offer: side gigs, creative skills, even unique services.</p></div>
                                        <div id="serviceText2" className="step"><h3 className="text-32">2</h3><p className="steps-text text-24">Include your working arrangements, schedule, price range, and other important details.</p></div>
                                        <div id="serviceText3" className="step"><h3 className="text-32">3</h3><p className="steps-text text-24">Accept bookings and earn from your side hustles!</p></div>
                                    </div>
                            </div>
                                <div className="bottom">
                                    <div id="phoneJSON">
                                        <Lottie
                                            id="json-anim"
                                            animationData={currentServiceAnim}
                                            loop={false}
                                            autoplay={serviceAutoplay}
                                            lottieRef={lottieRef}
                                            onComplete={() => {
                                                if (currentServiceAnim.layers[2].nm.includes("Offer 01")) {
                                                    setCurrentServiceAnim(offerAnimation2)
                                                    document.getElementById("serviceText2").style.opacity = 1;
                                                    document.getElementById("serviceText2").classList.add("fade-in-left");
                                                }

                                                if (currentServiceAnim.layers[2].nm.includes("Offer 02")) {
                                                    setCurrentServiceAnim(offerAnimation3)
                                                    document.getElementById("serviceText3").style.opacity = 1;
                                                    document.getElementById("serviceText3").classList.add("fade-in-left");
                                                }

                                                if (currentServiceAnim.layers[2].nm.includes("Offer 03")) {
                                                    setCurrentServiceAnim(offerAnimation1)
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* <div className="phone-shadow"></div> */}
                                </div>
                            </div>
                        </div>
                        {/* // End Service Section */}

                        {/* // Need Section */}
                        <div className="mobile-container need">

                            <div className="mobile-bg need"></div>

                            <div className="inner-mobile-container">
                                <div className="top">
                                    <div className="step-title-container">
                                        <img id="step-icon" src={needIconM} alt="" />
                                        <h3 className="text-42">Find what you need nearby</h3>
                                    </div>
                                    <div className="steps-container">
                                        <div id="needText1" className="step"><h3 className="text-32">1</h3><p className="steps-text text-24">Post what you’re looking for.</p></div>
                                        <div id="needText2" className="step"><h3 className="text-32">2</h3><p className="steps-text text-24">Add the details of the item or service, including your budget.</p></div>
                                        <div id="needText3" className="step"><h3 className="text-32">3</h3><p className="steps-text text-24">Wait for offers, review each option, then accept the best deal.</p></div>
                                    </div>
                            </div>
                                <div className="bottom">
                                    <div id="phoneJSON">
                                        <Lottie
                                            id="json-anim"
                                            animationData={currentNeedAnim}
                                            loop={false}
                                            autoplay={needAutoplay}
                                            lottieRef={lottieRef}
                                            onComplete={() => {
                                                if (currentNeedAnim.layers[2].nm.includes("Need 01")) {
                                                    setCurrentNeedAnim(needAnimation2)
                                                    document.getElementById("needText2").style.opacity = 1;
                                                    document.getElementById("needText2").classList.add("fade-in-left");
                                                }
                                                if (currentNeedAnim.layers.length > 4 && currentNeedAnim.layers[4].nm.includes("Need 02")) {
                                                    setCurrentNeedAnim(needAnimation3)
                                                    document.getElementById("needText3").style.opacity = 1;
                                                    document.getElementById("needText3").classList.add("fade-in-left");
                                                }
                                                if (currentNeedAnim.layers[2].nm.includes("Need 03")) {
                                                    setCurrentNeedAnim(needAnimation1)
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* <div className="phone-shadow"></div> */}
                                </div>
                            </div>
                        </div>
                        {/* // End Need Section */}
                    </>
                )}


                <div className="transitions-container">
                    <div id="Sell" className="sell-section"></div>
                    <div id="Service" className="service-section"></div>
                </div>
            </div>

            <JoinServbees />

        </Layout>
    )

}

export default HowTo