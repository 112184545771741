import React from "react"
import { Link } from "gatsby"

const sectionJoinServbees = () => {
  return (
    <div className="section-join-servbees">
      {/* <div className="container"> */}
      <p className="text">Ready to get Buzzing?</p>
      <div className="button-flex-container">
        <Link to="/download" className="btn btn-blue">
          Join Servbees
        </Link>
      </div>
      {/* </div> */}
    </div>
  )
}

export default sectionJoinServbees
